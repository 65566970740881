import React, { Component, Fragment } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import parser from 'html-react-parser';

import { Color, FontSans } from '../../config/variables';

interface Props {
  name: string;
  required?: Boolean;
  text: string;
  value: string;
  id: string;
  className?: string;
  onChange?: Function;
  checked: boolean;
}

export default class Checkbox extends Component<Props> {
  static defaultProps: Props = {
    name: undefined,
    required: false,
    text: undefined,
    value: undefined,
    id: undefined,
    checked: false,
  };

  constructor(props: Props) {
    super(props);
  }

  handleClick() {}

  render() {
    const { name, required, id, text, value, className, onChange, checked } = this.props;

    return (
      <div className={className}>
        <Box id={id} type="checkbox" name={name} value={value} required={required} checked={checked} onChange={onChange} />
        <Label htmlFor={id}>
          <Text>{parser(text)}</Text>
        </Label>
      </div>
    );
  }
}

const svg = color =>
  `url('data:image/svg+xml;utf8,${encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="14px" height="10px" viewBox="0 0 14 10"><path stroke="${color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12.33 1L5 8.33 1.67 5"/></svg>`
  )}')`;
const size = 1.25;
const Box = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  ${FontSans}

  &:before {
    display: block;
    flex: 0 0 ${size}rem;
    height: ${size}rem;
    background-color: ${Color.white};
    background-position: 50%;
    background-repeat: no-repeat;
    content: '';
    margin-right: .75rem;
    transition: background .3s ease;
  }
  
  ${Box}:focus ~ &:before {
    box-shadow: inset 0 0 0 2px ${Color.slate};
  }

  ${Box}:checked ~ &:before {
    background-image: ${svg(Color.slate)};
  }

  &:hover:before {
    background-image: ${svg(rgba(Color.slate, 0.16))};
  }
`;

const Text = styled.span`
  font-size: 0.75em;
  line-height: 1.25rem;
  letter-spacing: -0.01em;

  a {
    color: currentColor;
    text-decoration: underline;
  }
`;
