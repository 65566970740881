import { graphql, StaticQuery, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import Logo from './shared/logo';
import { WrapMax } from './shared/wraps';
import { Color, DynamicPadding2x } from '../config/variables';
import { Markdown } from 'src/components/shared';

interface Column {
  Column1: string;
  Column2: string;
  Column3: string;
  Column4: string;
}
interface Props {
  columns: Column;
}

export default class Footer extends Component<Props> {
  render() {
    const { columns } = this.props;
    return (
      <Outer>
        <Inner>
          <StyledLogo as={Logo} inverted />
          <StyledAddress>
            <Markdown content={columns.Column1} />
          </StyledAddress>
          <StyledAddress>
            <Markdown content={columns.Column2} />
          </StyledAddress>
          <StyledAddress>
            <Markdown content={columns.Column3} />
          </StyledAddress>
          <StyledNav>
            <Markdown content={columns.Column4} />
          </StyledNav>
        </Inner>
      </Outer>
    );
  }
}

const Heading = styled.h5`
  font-size: 1em;
  margin: 0 0 0.875em;
`;

const StyledLogo = styled.a`
  display: none;

  @media screen and (min-width: 1250px) {
    display: block;
  }
`;

const sharedP = css`
  color: ${Color.cloud3};
  font-size: 0.875em;
  line-height: 1.625rem;
  margin-top: 0;
`;

const StyledLink = styled(Link)`
  ${sharedP}
  display: block;
`;


const Paragraph = styled.p`
  ${sharedP}
`;


const StyledAddress = styled.address`
  p {
    ${sharedP}
  }  

  h3,
  h5 {
    color: ${Color.cloud3};
    font-size: 1em;
    margin: 0 0 0.875em;
  }
`;

const StyledNav = styled.nav`
  p {
    ${sharedP}
  }  

  h3,
  h5 {
    color: ${Color.cloud3};
    font-size: 1em;
    margin: 0 0 0.875em;
  }

  a {
    ${sharedP}
    border-bottom: none;

    &:hover {
      border-bottom: none;
    }
  }
`;

const Outer = styled.footer`
  ${DynamicPadding2x}
  background-color: ${Color.slate};
  color: ${Color.white}; 
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-gap: 2.5em 6.388888889vw;
  justify-content: space-between;
  align-items: start;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: 860px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 1250px) {
    grid-template-columns: none;
    grid-template-areas: 'logo . . . .';
  }
`;

const Menu = styled.nav`
  display: flex;
`;

const MenuItem = styled(Link)`
  display: block;
  font-size: 0.875em;
  padding: 0 0.25rem 0.25rem;
  margin: 0 1.875rem;
  font-weight: 600;

  &:hover {
    box-shadow: inset 0 -2px 0 0 ${Color.slate};
  }

  &.${props => props.activeClassName} {
    &,
    &:hover {
      box-shadow: inset 0 -2px 0 0 ${Color.citrus};
    }
  }
`;

const MobileMenuButton = styled.button``;
