import React from 'react';
import styled, { css } from 'styled-components';

import { Color, ScalingSize } from '../../config/variables';
interface Heading2Props {
  children?: any;
  hero?: boolean;
  startPage?: boolean;
  cta?: boolean;
  process?: boolean;
  markdown?: boolean;
}

const Heading2 = ({ children, hero, startPage, cta, process, markdown }: Heading2Props) => (
  <StyledHeading2 hero={hero} startPage={startPage} cta={cta} process={process} markdown={markdown}>
    {children}
  </StyledHeading2>
);

const StyledHeading2 = styled.h2`
  color: ${Color.slate};
  letter-spacing: -0.02em;

  ${props =>
    props.case
      ? css`
          ${ScalingSize('width', 280, 880)};
          ${ScalingSize('margin-right', 32, 103)};
        `
      : null};

  ${props =>
    props.hero
      ? css`
          margin: 0;
          ${ScalingSize('font-size', 20, 32)}

          @media screen and (min-width: 860px) {
            text-align: right;
            grid-column: 1;
          }
        `
      : null};

  ${props =>
    props.process
      ? css`
          margin: 0;

          @media screen and (min-width: 860px) {
            text-align: center;
            grid-column: 1;
          }
        `
      : null}

  ${props =>
    props.startPage
      ? css`
          margin: 0;
          ${ScalingSize('font-size', 20, 32)}

          @media screen and (min-width: 860px) {
            text-align: right;
            grid-column: 1;
          }
        `
      : null};

  ${props =>
    props.markdown
      ? css`
          ${ScalingSize('font-size', 24, 48)};
        `
      : null};
`;

export default Heading2;
