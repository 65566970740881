import React from 'react';
import styled, { css } from 'styled-components';

import { Color } from '../../config/variables';
interface ExcerptProps {
  children?: any;
  startPage?: boolean;
  hero?: boolean;
  markdown?: boolean;
  cookie?: boolean;
}

const Excerpt = ({ children, startPage, hero, markdown, cookie }: ExcerptProps) => (
  <StyledExcerpt startPage={startPage} hero={hero} markdown={markdown} cookie={cookie}>
    {children}
  </StyledExcerpt>
);

const StyledExcerpt = styled.p`
  margin: 1em 0 2em;
  color: ${Color.slate3};

  ${props =>
    props.startPage || props.hero
      ? css`
          line-height: 1.75;
          font-size: unset;
          margin: 0;

          @media screen and (min-width: 860px) {
            grid-column: 2;
          }
        `
      : null};

  ${props =>
    props.markdown
      ? css`
          line-height: 1.6;
          font-size: 1.25rem;
        `
      : null};
`;

export default Excerpt;
