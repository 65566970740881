import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import lottie from 'lottie-web';

import { Logo, Button, StickyMessage } from './shared';
import { WrapMax } from './shared/wraps';
import { Color, FontOsynlig, FontSans, ScalingSize } from '../config/variables';
import { menuDark, menuLight } from '../lottie-files';

interface Props {
  onCloseMobileMenu: Function;
  onToggleMobileMenu: Function;
  mobileMenuActive: boolean;
  location: any;
}

export default class Header extends Component<Props & any> {
  private menuButtonContainer1 = createRef<HTMLButtonElement>();
  private menuButtonContainer2 = createRef<HTMLButtonElement>();
  private menuButtonAnimation1: any;
  private menuButtonAnimation2: any;

  constructor(props: Props & any) {
    super(props);
  }

  componentDidMount() {
    this.menuButtonAnimation1 = lottie.loadAnimation({
      container: this.menuButtonContainer1.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: menuDark,
    });
    this.menuButtonAnimation2 = lottie.loadAnimation({
      container: this.menuButtonContainer2.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: menuLight,
    });
  }

  componentWillUnmount() {
    this.menuButtonAnimation1.destroy();
    this.menuButtonAnimation2.destroy();
  }

  render() {
    const { mobileMenuActive } = this.props;

    const path = this.props.location.pathname;
    const label = mobileMenuActive ? 'Stäng meny' : 'Öppna meny';

    return (
      <Outer>
        {/* <StickyMessage 
          show={true}
          message="Hej! fredag 17 april kommer vi att ha ett frukostseminarium om hur man skapar smarta prototyper. Kul va?"
        /> */}
        <Inner>
          <Logo />
          <Menu>
            <MenuItem bg={Color.citrus5} cover direction="left" to="/vad-vi-gor/" activeClassName="active">
              Vad vi gör
            </MenuItem>
            <MenuItem bg={Color.citrus5} cover direction="left" to="/hur-vi-jobbar/" activeClassName="active">
              Hur vi jobbar
            </MenuItem>
            <MenuItem bg={Color.citrus5} cover direction="left" to="/case/" activeClassName="active">
              Case
            </MenuItem>
            <MenuItem bg={Color.citrus5} cover direction="left" to="/artiklar/" activeClassName="active">
              Artiklar
            </MenuItem>
            <MenuItem bg={Color.citrus5} cover direction="left" to="/om-oss/" activeClassName="active">
              Om oss
            </MenuItem>
            <MenuItem bg={Color.citrus5} cover direction="left" to="/karriar/" activeClassName="active">
              Karriär
            </MenuItem>
          </Menu>
          <ContactButton to="/kontakta-oss/" theme="secondary" size="small" text="Kontakta oss" />
          <MobileMenuButton onClick={this.onToggleMobileMenu.bind(this)} ref={this.menuButtonContainer1} aria-label={label} />
        </Inner>
        <MobileMenu active={mobileMenuActive}>
          <MobileMenuInner>
            <Logo onClick={this.onCloseMobileMenu.bind(this)} inverted />
            <MobileMenuButton onClick={this.onToggleMobileMenu.bind(this)} ref={this.menuButtonContainer2} aria-label={label} />
          </MobileMenuInner>
          <MenuWrap>
            <MobileMenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/vad-vi-gor/" activeClassName="active">
              Vad vi gör
            </MobileMenuItem>
            <MobileMenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/hur-vi-jobbar/" activeClassName="active">
              Hur vi jobbar
            </MobileMenuItem>
            {/*            <SubmenuGroup show={path.includes('vad-vi-gor')} aria-hidden={path.includes('vad-vi-gor')}>
              <Submenu>
                <SubmenuTitle>Design</SubmenuTitle>
                <SubmenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/case/" activeClassName="active">
                  UX/UI
                </SubmenuItem>
                <SubmenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/case/" activeClassName="active">
                  Designsprint
                </SubmenuItem>
                <SubmenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/case/" activeClassName="active">
                  Prototyping
                </SubmenuItem>
              </Submenu>
              <Submenu>
                <SubmenuTitle>Strategi</SubmenuTitle>
                <SubmenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/case/" activeClassName="active">
                  Förstudie
                </SubmenuItem>
                <SubmenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/case/" activeClassName="active">
                  Innovation
                </SubmenuItem>
              </Submenu>
              <Submenu grow>
                <SubmenuTitle>Utveckling</SubmenuTitle>
                <SubmenuItem
                  onClick={this.onCloseMobileMenu.bind(this)}
                  bg={Color.citrus5}
                  cover
                  direction="left"
                  to="/vad-vi-gor/utveckling"
                  activeClassName="active"
                >
                  Frontend-utveckling
                </SubmenuItem>
                <SubmenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/case/" activeClassName="active">
                  Appar
                </SubmenuItem>
              </Submenu>
            </SubmenuGroup>*/}
            <MobileMenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/case/" activeClassName="active">
              Case
            </MobileMenuItem>
            <MobileMenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/artiklar/" activeClassName="active">
              Artiklar
            </MobileMenuItem>
            <MobileMenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/om-oss/" activeClassName="active">
              Om oss
            </MobileMenuItem>
            <MobileMenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/karriar/" activeClassName="active">
              Karriär
            </MobileMenuItem>
            <MobileMenuItem onClick={this.onCloseMobileMenu.bind(this)} bg={Color.citrus5} cover direction="left" to="/kontakta-oss/" activeClassName="active">
              Kontakta oss
            </MobileMenuItem>
          </MenuWrap>
        </MobileMenu>
      </Outer>
    );
  }

  onCloseMobileMenu() {
    const { onCloseMobileMenu } = this.props;

    this.menuButtonAnimation1.setDirection(-1);
    this.menuButtonAnimation2.setDirection(-1);

    this.menuButtonAnimation1.play();
    this.menuButtonAnimation2.play();

    onCloseMobileMenu();
  }

  onToggleMobileMenu() {
    const { mobileMenuActive, onToggleMobileMenu } = this.props;

    this.menuButtonAnimation1.setDirection(mobileMenuActive ? -1 : 1);
    this.menuButtonAnimation2.setDirection(mobileMenuActive ? -1 : 1);

    this.menuButtonAnimation1.play();
    this.menuButtonAnimation2.play();

    onToggleMobileMenu();
  }
}

const Outer = styled.header`
  order: -30;
  padding: 2.75em 0;
  position: relative;
  z-index: 1500;
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-template-areas: 'logo menu contact';
  grid-auto-columns: max-content;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media screen and (max-width: 1139px) {
    grid-template-areas: 'logo menu mobile';
  }

  @media screen and (max-width: 1022px) {
    grid-template-areas: 'logo mobile';
  }
`;

const MobileMenuInner = styled(Inner)`
  padding-top: 2.75em;
  padding-bottom: 2.75em;
  z-index: 2;
  grid-template-areas: 'logo mobile';
  color: ${Color.white};

  @media screen and (max-width: 1139px) {
    grid-template-areas: 'logo mobile';
  }
`;

const Menu = styled.nav`
  display: none;

  @media screen and (min-width: 1022px) {
    display: flex;
  }
`;

const MenuItem = styled(AniLink)`
  display: block;
  font-size: .875em;
  padding: 0 .25rem .25rem;
  /* margin: 0 1.875rem; */
  ${ScalingSize('margin-left', 8, 28)}
  ${ScalingSize('margin-right', 8, 28)}
  ${FontOsynlig}
  color: ${Color.slate};

  &:hover {
    box-shadow: inset 0 -2px 0 0 ${Color.citrus};
  }

  &.${props => props.activeClassName} {
    &, &:hover {
      box-shadow: inset 0 -2px 0 0 ${Color.slate};
    }
  }
`;

const ContactButton = styled(Button)`
  @media screen and (max-width: 1022px) {
    display: none;
  }
`;

const MENU_TIMING = 400;

const MenuWrap = styled(WrapMax)``;

const MobileMenu = styled.nav`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  height: 100vh;
  clip-path: inset(-20em 0 -999em 100vw);
  transition: all ${MENU_TIMING}ms cubic-bezier(0, 0.32, 0.4, 1);
  padding-bottom: 4em;
  overflow-y: auto;
  visibility: hidden;

  ${MenuWrap} {
    transition: all 450ms ease 200ms;
    transform: translateX(2vw);
  }

  .mobile-menu-active & ${MenuWrap} {
    transform: none;
  }

  &:before {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 999vh;
    background-color: ${Color.slate};
    content: '';
    opacity: 0;
    transition: opacity 0s linear ${MENU_TIMING}ms;
  }

  ${props =>
    props.active &&
    `
    visibility: visible;
    clip-path: inset(-20em 0 -999em 0);
    pointer-events: all;

    &:before {
      opacity: 1;
      transition: opacity 0s linear;
    }
  `}
`;

const MobileMenuItem = styled(AniLink)`
  ${FontOsynlig}
  display: block;
  position: relative;
  font-size: 1.5em;
  line-height: 2rem;
  color: ${Color.white};
  margin-bottom: 2rem;

  &.active {
    color: ${Color.citrus};
  }

  &.ancestor {
    color: ${Color.citrus5};
  }
`;

const MobileMenuButton = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  background: none;
  appearance: none;
  border: 0;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media screen and (min-width: 1022px) {
    display: none;
  }
`;

const SubmenuGroup = styled.nav`
  position: relative;
  margin: -0.5em 0 3em;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2em;
  display: ${props => (props.show ? 'grid' : 'none')};
`;

const Submenu = styled.div``;
// ${props => props.grow && `grid-column: 1 / 3;`}

const SubmenuTitle = styled.span`
  display: block;
  ${FontSans};
  text-transform: uppercase;
  font-size: 0.6875em;
  line-height: 1.5rem;
  color: ${Color.slate5};
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const SubmenuItem = styled(AniLink)`
  ${FontOsynlig};
  display: block;
  color: ${Color.citrus5};
  line-height: 1.5rem;
  margin-bottom: 1em;
  white-space: nowrap;
`;
