import React, { Component } from 'react';
import styled from 'styled-components';

import { ContactCard, Button } from './';
// import { Color, FontOsynlig } from '../../config/variables'
import { Color, FontOsynlig } from '../../config/variables';

interface Props {
  show?: boolean;
  message: string;
}

const who = {
  name: '',
  role: '',
  image: {
    src: 'linda.png',
    alt: 'Porträttbild på Linda Marech Bergström',
  },
  color: 'white',
};

export default class StickyMessage extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { show, message } = this.props;

    return (
      <Wrap>
        <Message>
          <p>{message}</p>

          <Button theme="ghost" text="Utforska" icon="ArrowRight" />
        </Message>
        <ContactWrap>
          <ContactCard key={who.name} data={who} />
        </ContactWrap>
      </Wrap>
    );
  }
}

const Wrap = styled.div`
  position: fixed;
  right: 1rem;
  top: 1rem;
  display: flex;
  align-items: center;
  z-index: 3;

  @media screen and (min-width: 812px) {
    right: 2rem;
    top: 2rem;
  }
`;

const ContactWrap = styled.div`
  width: 3.5rem;
  height: 4.5rem;
`;

const Message = styled.div`
  max-width: 19rem;
  background-color: ${Color.iris};
  color: ${Color.white};
  border-radius: 1rem;
  padding: 0 1.5rem 1rem 1.5rem;
  ${FontOsynlig}
  font-size: 0.75em;
  flex: 0 0 auto;
  height: auto;
  line-height: 1.666666667;
  position: relative;
  margin-right: 1rem;
  top: 1.75rem;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 1.5em;
    right: -0.8em;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;

    border-left: 16px solid ${Color.iris};
  }
`;
