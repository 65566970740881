import React from 'react';
import styled, { css } from 'styled-components';

import { Color, ScalingSize } from '../../config/variables';
interface Heading1Props {
  children?: any;
  center?: boolean;
  startPage?: boolean;
  primary?: boolean;
  process?: boolean;
  markdown?: boolean;
}

const Heading1 = ({ children, center, startPage, primary, process, markdown }: Heading1Props) => (
  <StyledHeading1 center={center} startPage={startPage} primary={primary} process={process} markdown={markdown}>
    {children}
  </StyledHeading1>
);

const StyledHeading1 = styled.h1`
  color: ${Color.slate};
  letter-spacing: -0.03em;
  line-height: 1.1;
  ${ScalingSize('font-size', 40, 128, 1.430555556)};

  ${props =>
    props.primary
      ? css`
          ${ScalingSize('margin-top', 40, 88)};
          ${ScalingSize('margin-bottom', 32, 72)};
          margin: 0 0 0.81em;
          hyphens: auto;
        `
      : null};

  ${props =>
    props.case
      ? css`
          ${ScalingSize('width', 280, 880)};
          ${ScalingSize('margin-right', 32, 103)};
        `
      : null};

  ${props => (props.center ? 'text-align: center' : null)};

  ${props =>
    props.markdown
      ? css`
          @media screen and (min-width: 796px) {
            text-align: center;
          }
        `
      : null};

  ${props =>
    props.process
      ? css`
          margin: 0;
          margin-bottom: 0.5rem;

          @media screen and (min-width: 860px) {
            text-align: center;
            grid-column: 1 / 2;
            margin-bottom: 0;
          }
        `
      : null}

  ${props =>
    props.startPage
      ? css`
          margin: 0;
          margin-bottom: 1.5rem;

          @media screen and (min-width: 860px) {
            text-align: center;
            grid-column: 1 / 2;
            margin-bottom: 0;
            margin-right: auto;
            margin-left: auto;
            max-width: 80%;
          }
        `
      : null};
`;

export default Heading1;
