import React, { Component } from 'react';
import styled from 'styled-components';

import Image from '../Image';
import { ScalingSize } from '../../config/variables';
import Fade from 'react-reveal/Fade';

interface Item {
  filename: string;
  alt: string;
}

interface Props {
  items: Item[];
}

export default class Mosaic extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { items } = this.props;

    return (
      <Fade bottom distance={'10%'} fraction={0.2}>
        <Outer>
          {items.map((item, index) => (
            <ImageWrap key={`mosaic_${index}`}>
              <Image filename={item.filename} alt={item.alt} />
            </ImageWrap>
          ))}
        </Outer>
      </Fade>
    );
  }
}

const Outer = styled.figure`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  ${ScalingSize('grid-gap', 16, 24)};
  margin: 0;
  ${ScalingSize('margin-top', 48, 112)};
  ${ScalingSize('margin-bottom', 48, 112)};
`;

const ImageWrap = styled.div`
  grid-column: 1;
  grid-row: 2;
  
  &:first-child {
    margin-top: 2.96875vw;
    grid-column: 1;
    grid-row: 1;
  }
  
  &:last-child {
    grid-column: 2;
    grid-row: 1 / 3;
    height: 100%;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    
    .gatsby-image-wrapper {
      flex: 0 0 100%;
    }
  }

`;
