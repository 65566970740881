import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { textCleanup } from 'src/shared/utils';
import { Color, ScalingSize } from 'src/config/variables';
import { Heading1, Heading2, Heading3, Paragraph, Excerpt } from 'src/components/shared';

const Markdown = ({ content, indented }) => (
  <Wrapper indented={indented}>
    <ReactMarkdown
      source={textCleanup(content)}
      renderers={{
        paragraph: props => <Paragraph markdown {...props} />,
        blockquote: props => <Blockquote {...props} />,
        link: props => <Link {...props} />,
        heading: Heading,
        list: props => <List {...props} />,
      }}
    />
  </Wrapper>
);

// Available renderers
// https://github.com/rexxars/react-markdown/blob/master/src/renderers.js

const Wrapper = styled.div`
  ${({ indented }) =>
    indented &&
    `
    grid-column: 1 / 32;
    margin-top: 1rem;
    width: 100%;

    @media screen and (min-width: 978px) {
      width: 85%;
      grid-column: 3 / 16;
    }
  `}
`;

const UnorderedList = styled.ul`
  line-height: 1.6;
  margin: 1em 0 2em;
  color: ${Color.slate3};
  padding-left: 1em;

  li {
    ${ScalingSize('margin-bottom', 8, 16)}
  }
`;

const OrderedList = styled.ol`
  line-height: 1.6;
  margin: 1em 0 2em;
  color: ${Color.slate3};
  padding-left: 1em;

  p + & {
    margin-top: -1em;
  }

  li {
    padding-left: 0.5rem;

    p {
      margin: 0;

      p + & {
        margin-top: 1em;
      }
    }
  }

  li + li {
    margin-top: 1em;
  }
`;

const Link = styled.a`
  color: ${Color.slate};
  border-bottom: 1px solid ${Color.citrus};
  transition: border 0.2s ease;

  &:hover {
    border-bottom: 1px solid ${Color.slate};
  }
`;

const Blockquote = styled.blockquote`
  line-height: 1.75;
  margin: 1em 0 2em;
  padding-left: 1.25em;
  color: ${Color.iris};
  border-left: 3px solid ${Color.citrus4};

  p {
    color: ${Color.iris};
  }

  @media screen and (min-width: 769px) {
    padding-left: 2em;
  }
`;

const Heading = props => {
  switch (props.level) {
    case 1:
      return (
        <Heading1 primary markdown {...getCoreProps(props)}>
          {props.children}
        </Heading1>
      );
    case 2:
      return (
        <Heading2 markdown {...getCoreProps(props)}>
          {props.children}
        </Heading2>
      );
    case 3:
      return (
        <Heading3 markdown {...getCoreProps(props)}>
          {props.children}
        </Heading3>
      );
    case 5:
      return (
        <Excerpt markdown {...getCoreProps(props)}>
          {props.children}
        </Excerpt>
      );
    default:
      return createElement(`h${props.level}`, getCoreProps(props), props.children);
  }
};

const getCoreProps = props => {
  return props['data-sourcepos'] ? { 'data-sourcepos': props['data-sourcepos'] } : {};
};

export default Markdown;
