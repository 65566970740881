import { css } from 'styled-components';

interface IColor {
  [color: string]: string;
}

const FontSans = css`
  font-family: Merriweather Sans, sans-serif;
`;

const FontOsynlig = css`
  font-family: Madera Osynlig, Merriweather Sans, sans-serif;
  font-feature-settings: 'ss01' on;
  font-weight: normal;
`;

const FontSerif = css`
  font-family: Merriweather, serif;
`;

const ElevationSoft = css`
  box-shadow: 0px 1.49432px 2.21381px rgba(19, 18, 16, 0.0112458), 0px 3.59106px 5.32008px rgba(19, 18, 16, 0.0161557),
    0px 6.76164px 10.0172px rgba(19, 18, 16, 0.02), 0px 12.0616px 17.869px rgba(19, 18, 16, 0.0238443), 0px 22.5599px 33.4221px rgba(19, 18, 16, 0.0287542),
    0px 54px 80px rgba(19, 18, 16, 0.04);
`;

const ScalingSize = (property: string, min: number, max: number, multiplier: number = 1) => {
  const vwSize = max / (multiplier * 1440);
  const vwBreakpoint = Math.ceil(min / vwSize) || 0;
  return css`
    ${property}: ${min / 16}rem;

    @media screen and (min-width: ${vwBreakpoint}px) {
      ${property}: ${vwSize * 100}vw;
    }

    @media screen and (min-width: ${multiplier * 1440}px) {
      ${property}: ${max / 16}rem;
    }
  `;
};

const DynamicPadding = css`
  ${ScalingSize('padding-top', 28, 64, 1.166666667)}
  ${ScalingSize('padding-bottom', 28, 64, 1.166666667)}
`;

const DynamicPadding2x = css`
  ${ScalingSize('padding-top', 56, 128, 1.166666667)}
  ${ScalingSize('padding-bottom', 56, 128, 1.166666667)}
`;

const DynamicMargin = css`
  ${ScalingSize('margin-top', 56, 128, 1.166666667)}
  ${ScalingSize('margin-bottom', 56, 128, 1.166666667)}
`;

const Color: IColor = {
  begonia: '#ff4a49',
  begonia2: '#ff5c5b',
  begonia3: '#ff7877',
  begonia4: '#ffa4a4',
  begonia5: '#ffd2d1',
  citrus: '#f4da00',
  citrus2: '#f5dd19',
  citrus3: '#f7e440',
  citrus4: '#f9ec7f',
  citrus5: '#fcf6bf',
  slate: '#252931',
  slate2: '#3a3e45',
  slate3: '#5c5f65',
  slate4: '#929498',
  slate5: '#c8c9cb',
  cloud: '#d7d8de',
  cloud2: '#dbdbe1',
  cloud3: '#e1e2e7',
  cloud4: '#ebebee',
  cloud5: '#f5f5f7',
  champ: '#ece5d4',
  champ2: '#f1ecdf',
  champ3: '#f1ecdf',
  champ4: '#f5f2e9',
  champ5: '#faf8f4',
  white: '#fff',
  white80: 'rgba(255, 255, 255, .8)',
  white56: 'rgba(255, 255, 255, .56)',
  white32: 'rgba(255, 255, 255, .4)',
  white16: 'rgba(255, 255, 255, .16)',
  iris: '#005f6a',
  iris2: '#196f78',
  iris3: '#408790',
  iris4: '#7fafb4',
  iris5: '#bfd7da',
  black: '#000000',
};

export { Color, FontSans, FontOsynlig, FontSerif, ScalingSize, ElevationSoft, DynamicPadding, DynamicPadding2x, DynamicMargin };
