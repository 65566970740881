import React, { Component } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import * as shortid from 'shortid';

import { Color } from '../../config/variables';

interface Props {
  inverted?: Boolean;
  size?: Number;
  className?: string;
  onClick: Function;
}

export default class Logo extends Component<Props> {
  static defaultProps: Props = {
    inverted: false,
    size: 9.25,
    onClick: undefined,
  };

  render() {
    const { inverted, size, className, onClick } = this.props;
    const foreground = inverted ? Color.white : Color.slate;
    const id = shortid.generate();

    if (inverted) {
      return (
        <AniLink onClick={onClick && onClick} bg={Color.citrus5} cover direction="left" className={className} to="/" aria-label="Osynlig AB - till startsidan">
          <LogoSvg size={size} viewBox="0 0 502 122" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m44.375 44.3999c12.1-12.1 29.2-15.9 44.6-11.5-2-7.2-5.9-13.9-11.5-19.6-17.8-17.7-46.5-17.7-64.2 0s-17.7 46.4 0 64.1c5.6 5.6 12.4 9.5 19.6 11.5-4.4-15.3-.6-32.4 11.5-44.5z"
              fill="#f4da00"
            />
            <g fill="#fff">
              <path d="m76.4751 38.1001c10.2 0 19.9 4 27.0999 11.2 7.2 7.2 11.2 16.9 11.2 27.1s-4 19.9-11.2 27.0999c-7.1999 7.2-16.8999 11.2-27.0999 11.2s-19.9-4-27.1-11.2c-15-14.9999-15-39.2999 0-54.1999 7.2-7.2 16.8-11.2 27.1-11.2zm0-7c-11.6 0-23.2 4.4-32.1 13.3-17.7 17.7-17.7 46.4 0 64.0999 8.9 8.9 20.5 13.3 32.1 13.3s23.2-4.4 32.0999-13.3c17.7-17.6999 17.7-46.3999 0-64.0999-8.8999-8.9-20.4999-13.3-32.0999-13.3z" />
              <path d="m45.375 7c10.2 0 19.9 4 27.1 11.2 15 15 15 39.3 0 54.2-7.2 7.2-16.9 11.2-27.1 11.2s-19.9-4-27.1-11.2c-15-15-15-39.3 0-54.2 7.2-7.2 16.8-11.2 27.1-11.2zm0-7c-11.7 0-23.3 4.4-32.1 13.3-17.7 17.7-17.7 46.4 0 64.1 8.9 8.9 20.5 13.3 32.1 13.3s23.2-4.4 32.1-13.3c17.7-17.7 17.7-46.4 0-64.1-8.9-8.9-20.5-13.3-32.1-13.3z" />
              <path d="m69.675 82.5999c-.6 0-1.3-.2-1.8-.7l-28-28c-1-1-1-2.6 0-3.5 1-1 2.6-1 3.5 0l28 28c1 1 1 2.6 0 3.5-.4.5-1.1.7-1.7.7z" />
              <path d="m79.575 71.6999c-.6 0-1.3-.2-1.8-.7l-27.4-27.6c-1-1-1-2.6 0-3.5 1-1 2.6-1 3.5 0l27.5 27.5c1 1 1 2.6 0 3.5-.5.5-1.1.8-1.8.8z" />
              <path d="m86.0749 57.2c-.6 0-1.3-.2-1.8-.7l-19.5-19.5c-1-1-1-2.6 0-3.5 1-1 2.6-1 3.5 0l19.5 19.5c1 1 1 2.6 0 3.5-.4.5-1.1.7-1.7.7z" />
              <path d="m55.175 89.0999c-.6 0-1.3-.2-1.8-.7l-20-20c-1-1-1-2.6 0-3.5 1-1 2.6-1 3.5 0l20 20c1 1 1 2.6 0 3.5-.4.5-1 .7-1.7.7z" />
              <path d="m235.775 90.2999c-9.7 0-17-4.3-20.5-12-.8-1.8 0-4 1.8-4.8s4 0 4.8 1.8c2.9 6.4 9.1 7.7 13.9 7.7 7.2 0 11.7-3.5 11.7-9.1 0-5-3.9-6.9-12.8-10-9.2-3-18-5.8-18-16.5 0-9.4 7.4-16 17.9-16 7.8 0 14.1 3.7 17.8 10.5 1 1.8.3 3.9-1.4 4.9-1.8 1-3.9.3-4.9-1.4-2.4-4.5-6.3-6.7-11.5-6.7-6.5 0-10.7 3.5-10.7 8.8 0 4.7 2.8 6.3 13.1 9.6 8.4 2.9 17.8 6.2 17.8 16.8-.1 9.7-7.9 16.4-19 16.4z" />
              <path d="m284.375 89.0001c-2 0-3.6-1.6-3.6-3.6v-18.7l-17.6-29.1c-1-1.7-.5-3.9 1.2-5 1.7-1 3.9-.5 5 1.2l15.1 24.9 15-25.2c1-1.7 3.2-2.3 5-1.3 1.7 1 2.3 3.2 1.3 5l-17.8 29.7v18.5c0 2-1.6 3.6-3.6 3.6z" />
              <path d="m473.975 90.3c-16.9 0-29.7-12.6-29.7-29.4s12.6-29.4 29.4-29.4c9.6 0 18.3 4.5 23.4 12.1 1.1 1.7.7 3.9-1 5s-3.9.7-5-1c-3.7-5.6-10.2-8.9-17.4-8.9-12.6 0-22.2 9.5-22.2 22.2s9.7 22.2 22.5 22.2c10.7 0 18.3-5.7 19.9-14.4h-15.8c-2 0-3.6-1.6-3.6-3.6s1.6-3.6 3.6-3.6h19.7c2 0 3.6 1.6 3.6 3.6.1 14.6-11.4 25.2-27.4 25.2z" />
              <path d="m358.475 88.9997c-1.2 0-2.3-.6-3-1.5l-28.5-40.3v38.3c0 2-1.6 3.6-3.6 3.6s-3.6-1.6-3.6-3.6v-49.7c0-1.6 1-3 2.5-3.4 1.5-.5 3.1.1 4 1.4l28.6 40.2v-38.2c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6v49.7c0 1.6-1 3-2.5 3.4-.4.1-.8.1-1.1.1z" />
              <path d="m409.575 88.9998h-25.5c-2 0-3.6-1.6-3.6-3.6v-50c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6v46.4h21.9c2 0 3.6 1.6 3.6 3.6s-1.6 3.6-3.6 3.6z" />
              <path d="m428.875 88.9998c-2 0-3.6-1.6-3.6-3.6v-50c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6v50c0 2-1.6 3.6-3.6 3.6z" />
              <path d="m176.675 89.9998c-16.3 0-29.1-12.8-29.1-29.1s12.8-29.1 29.1-29.1c16 0 29.1 13 29.1 29.1 0 16.3-12.8 29.1-29.1 29.1zm0-50.9c-12.3 0-21.9 9.6-21.9 21.9s9.6 21.9 21.9 21.9 21.9-9.6 21.9-21.9-9.7-21.9-21.9-21.9z" />
            </g>
          </LogoSvg>
        </AniLink>
      );
    }

    return (
      <AniLink onClick={onClick && onClick} bg={Color.citrus5} cover direction="left" className={className} to="/" aria-label="Osynlig AB - till startsidan">
        <LogoSvg size={size} viewBox="0 0 502 122" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M44.3751 44.3999C56.4751 32.2999 73.5751 28.4999 88.9751 32.8999C86.9751 25.6999 83.0751 18.9999 77.4751 13.2999C59.7751 -4.4001 31.0751 -4.4001 13.3751 13.2999C-4.3249 30.9999 -4.3249 59.6999 13.3751 77.3999C18.9751 82.9999 25.7751 86.8999 32.9751 88.8999C28.4751 73.5999 32.2751 56.4999 44.3751 44.3999Z"
            fill="#F4DA00"
          />
          <path
            d="M76.4751 38.1001C86.6751 38.1001 96.3751 42.1001 103.575 49.3001C110.775 56.5001 114.775 66.2001 114.775 76.4001C114.775 86.6001 110.775 96.3001 103.575 103.5C96.3751 110.7 86.6751 114.7 76.4751 114.7C66.2751 114.7 56.5751 110.7 49.3751 103.5C34.3751 88.5001 34.3751 64.2001 49.3751 49.3001C56.5751 42.1001 66.1751 38.1001 76.4751 38.1001ZM76.4751 31.1001C64.8751 31.1001 53.2751 35.5001 44.3751 44.4001C26.6751 62.1001 26.6751 90.8001 44.3751 108.5C53.2751 117.4 64.8751 121.8 76.4751 121.8C88.0751 121.8 99.6751 117.4 108.575 108.5C126.275 90.8001 126.275 62.1001 108.575 44.4001C99.6751 35.5001 88.0751 31.1001 76.4751 31.1001Z"
            fill="#252931"
          />
          <path
            d="M45.375 7C55.575 7 65.275 11 72.475 18.2C87.475 33.2 87.475 57.5 72.475 72.4C65.275 79.6 55.575 83.6 45.375 83.6C35.175 83.6 25.475 79.6 18.275 72.4C3.275 57.4 3.275 33.1 18.275 18.2C25.475 11 35.075 7 45.375 7ZM45.375 0C33.775 0 22.175 4.39999 13.275 13.3C-4.425 31 -4.425 59.7 13.275 77.4C22.175 86.3 33.775 90.7 45.375 90.7C56.975 90.7 68.575 86.3 77.475 77.4C95.175 59.7 95.175 31 77.475 13.3C68.575 4.39999 56.975 0 45.375 0Z"
            fill="#252931"
          />
          <path
            d="M69.675 82.5999C69.075 82.5999 68.375 82.3999 67.875 81.8999L39.875 53.8999C38.875 52.8999 38.875 51.2999 39.875 50.3999C40.875 49.3999 42.475 49.3999 43.375 50.3999L71.375 78.3999C72.375 79.3999 72.375 80.9999 71.375 81.8999C70.975 82.3999 70.275 82.5999 69.675 82.5999Z"
            fill="#252931"
          />
          <path
            d="M79.5749 71.7C78.9749 71.7 78.2749 71.5 77.7749 71L50.2749 43.5C49.2749 42.5 49.2749 40.9 50.2749 40C51.2749 39 52.8749 39 53.7749 40L81.2749 67.5C82.2749 68.5 82.2749 70.1 81.2749 71C80.8749 71.4 80.2749 71.7 79.5749 71.7Z"
            fill="#252931"
          />
          <path
            d="M86.0749 57.2C85.4749 57.2 84.7749 57 84.2749 56.5L64.7749 37C63.7749 36 63.7749 34.4 64.7749 33.5C65.7749 32.5 67.3749 32.5 68.2749 33.5L87.7749 53C88.7749 54 88.7749 55.6 87.7749 56.5C87.3749 57 86.6749 57.2 86.0749 57.2Z"
            fill="#252931"
          />
          <path
            d="M55.175 89.0999C54.575 89.0999 53.875 88.8999 53.375 88.3999L33.375 68.3999C32.375 67.3999 32.375 65.7999 33.375 64.8999C34.375 63.8999 35.975 63.8999 36.875 64.8999L56.875 84.8999C57.875 85.8999 57.875 87.4999 56.875 88.3999C56.475 88.8999 55.875 89.0999 55.175 89.0999Z"
            fill="#252931"
          />
          <path
            d="M235.775 90.2999C226.075 90.2999 218.775 85.9999 215.275 78.2999C214.475 76.4999 215.275 74.2999 217.075 73.4999C218.875 72.6999 221.075 73.4999 221.875 75.2999C224.775 81.6999 230.975 82.9999 235.775 82.9999C242.975 82.9999 247.475 79.4999 247.475 73.8999C247.475 68.8999 243.575 66.9999 234.675 63.8999C225.475 60.8999 216.675 58.0999 216.675 47.3999C216.675 37.9999 224.075 31.3999 234.575 31.3999C242.375 31.3999 248.675 35.0999 252.375 41.8999C253.375 43.6999 252.675 45.7999 250.975 46.7999C249.175 47.7999 247.075 47.0999 246.075 45.3999C243.675 40.8999 239.775 38.6999 234.575 38.6999C228.075 38.6999 223.875 42.1999 223.875 47.4999C223.875 52.1999 226.675 53.7999 236.975 57.0999C245.375 59.9999 254.775 63.2999 254.775 73.8999C254.675 83.5999 246.875 90.2999 235.775 90.2999Z"
            fill="#252931"
          />
          <path
            d="M284.375 89.0001C282.375 89.0001 280.775 87.4001 280.775 85.4001V66.7001L263.175 37.6001C262.175 35.9001 262.675 33.7001 264.375 32.6001C266.075 31.6001 268.275 32.1001 269.375 33.8001L284.475 58.7001L299.475 33.5001C300.475 31.8001 302.675 31.2001 304.475 32.2001C306.175 33.2001 306.775 35.4001 305.775 37.2001L287.975 66.9001V85.4001C287.975 87.4001 286.375 89.0001 284.375 89.0001Z"
            fill="#252931"
          />
          <path
            d="M473.975 90.3C457.075 90.3 444.275 77.7 444.275 60.9C444.275 44.1 456.875 31.5 473.675 31.5C483.275 31.5 491.975 36 497.075 43.6C498.175 45.3 497.775 47.5 496.075 48.6C494.375 49.7 492.175 49.3 491.075 47.6C487.375 42 480.875 38.7 473.675 38.7C461.075 38.7 451.475 48.2 451.475 60.9C451.475 73.6 461.175 83.1 473.975 83.1C484.675 83.1 492.275 77.4 493.875 68.7H478.075C476.075 68.7 474.475 67.1 474.475 65.1C474.475 63.1 476.075 61.5 478.075 61.5H497.775C499.775 61.5 501.375 63.1 501.375 65.1C501.475 79.7 489.975 90.3 473.975 90.3Z"
            fill="#252931"
          />
          <path
            d="M358.475 88.9997C357.275 88.9997 356.175 88.3997 355.475 87.4997L326.975 47.1997V85.4997C326.975 87.4997 325.375 89.0997 323.375 89.0997C321.375 89.0997 319.775 87.4997 319.775 85.4997V35.7997C319.775 34.1997 320.775 32.7997 322.275 32.3997C323.775 31.8997 325.375 32.4997 326.275 33.7997L354.775 74.0997V35.7997C354.775 33.7997 356.375 32.1997 358.375 32.1997C360.375 32.1997 361.975 33.7997 361.975 35.7997V85.4997C361.975 87.0997 360.975 88.4997 359.475 88.8997C359.175 88.9997 358.775 88.9997 358.475 88.9997Z"
            fill="#252931"
          />
          <path
            d="M409.575 88.9998H384.075C382.075 88.9998 380.475 87.3998 380.475 85.3998V35.3998C380.475 33.3998 382.075 31.7998 384.075 31.7998C386.075 31.7998 387.675 33.3998 387.675 35.3998V81.7998H409.575C411.575 81.7998 413.175 83.3998 413.175 85.3998C413.175 87.3998 411.575 88.9998 409.575 88.9998Z"
            fill="#252931"
          />
          <path
            d="M428.875 88.9998C426.875 88.9998 425.275 87.3998 425.275 85.3998V35.3998C425.275 33.3998 426.875 31.7998 428.875 31.7998C430.875 31.7998 432.475 33.3998 432.475 35.3998V85.3998C432.475 87.3998 430.875 88.9998 428.875 88.9998Z"
            fill="#252931"
          />
          <path
            d="M176.675 89.9998C160.375 89.9998 147.575 77.1998 147.575 60.8998C147.575 44.5998 160.375 31.7998 176.675 31.7998C192.675 31.7998 205.775 44.7998 205.775 60.8998C205.775 77.1998 192.975 89.9998 176.675 89.9998ZM176.675 39.0998C164.375 39.0998 154.775 48.6998 154.775 60.9998C154.775 73.2998 164.375 82.8998 176.675 82.8998C188.975 82.8998 198.575 73.2998 198.575 60.9998C198.575 48.6998 188.875 39.0998 176.675 39.0998Z"
            fill="#252931"
          />
        </LogoSvg>
      </AniLink>
    );
  }
}

const LogoSvg = styled.svg`
  width: ${props => props.size}em;
`;
