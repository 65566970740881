import React from 'react';
import styled from 'styled-components';

import { FontSans, Color } from '../../config/variables';

const External = ({ to, blank = false, children }) => (
  <Link href={to} target={blank ? '_blank' : null} rel="noopener noreferrer">
    {children}
  </Link>
);

const Link = styled.a`
  color: ${Color.slate};
  border-bottom: 1px solid ${Color.citrus};
  transition: border 0.3s ease;

  &:hover,
  &:focus {
    border-color: ${Color.slate};
  }
`;

export default External;
