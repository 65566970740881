import React, { Component } from 'react';
import styled from 'styled-components';

import { Color } from '../../config/variables';
import { WrapMax } from './wraps';

interface Column {
  content: string;
}

interface Props {
  columns: Column[];
}

export default class TwoColumns extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { columns } = this.props;

    return (
      <WrapMax>
        <Row>
          {columns.map((column, index) => (
            <Column className={`twocolumns_${index}`} key={`twocolumns_${index}`}>
              {column.content}
            </Column>
          ))}
        </Row>
      </WrapMax>
    );
  }
}

const Row = styled.div`
  display: grid;
  grid-gap: 1em;
  max-width: 61.625em;
  justify-self: center;
  align-items: start;
  margin: 0 auto;

  @media screen and (min-width: 860px) {
    grid-gap: 4em 3em;
    grid-template-columns: 1fr 1fr;
  }
`;

const Column = styled.p`
  margin: 0;
  color: ${Color.slate3};
  line-height: 1.75;

  @media screen and (min-width: 860px) {
    font-size: 1em;
  }
`;
