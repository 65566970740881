import React, { Component } from 'react';
import styled from 'styled-components';

import Image from '../Image';

import { Link } from 'gatsby';

import { Color } from '../../config/variables';

interface Image {
  imageFile: { relativePath: string };
  url: string;
  alternativeText: string;
  color: string;
}

interface Props {
  align?: boolean;
  image: Image;
}

export default class ContactImage extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { image, align } = this.props;

    return (
      <Figure align={align} background={image.color}>
        <StyledImage>
          {image.imageFile ? (
            <Image filename={`${image.imageFile.relativePath}`} alt={image.alternativeText} />
          ) : (
            <Image filename="shadow.png" alt={image.alternativeText} />
          )}
        </StyledImage>
      </Figure>
    );
  }
}

const translate = '12.5%';
const colorKeys = Object.keys(Color);
const Figure = styled.figure`
  margin: 0;
  position: relative;
  flex: 0 0 100%;

  ${props =>
    props.align &&
    `
    transform: translateY(-11.763295449%);
  `}

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding-bottom: 100%;
    content: '';
    border-radius: 999em;
    background-color: ${props => (props.background ? Color[props.background] : Color[colorKeys[Math.floor(Math.random() * colorKeys.length)]])};
    transition: all 0.22s cubic-bezier(0.51, -0.31, 0.57, 1.39);
    transform: translateY(${translate});
  }
`;

const StyledImage = styled.div`
  transition: all 0.22s cubic-bezier(0.51, -0.31, 0.57, 1.39);
  position: relative;
  clip-path: url(#contact);
  will-change: transform;
  width: 100%;

  img {
    backface-visibility: hidden;
    transform: translateZ(0);
  }
`;
