import styled, { css } from 'styled-components'
const WrapWidth = css`
  max-width: 90rem;
`;


const WrapXsWidth = css`
  max-width: calc(42.5rem + 14.444444444vw);

  @media screen and (min-width: 1440px) {
    max-width: 55.5rem;
  }
`;

const WrapSmallWidth = css`
  max-width: calc(50rem + 14.444444444vw);

  @media screen and (min-width: 1440px) {
    max-width: 63rem;
  }
`;

const WrapMediumWidth = css`
  max-width: calc(61.25rem + 14.444444444vw);

  @media screen and (min-width: 1440px) {
    max-width: 74.25rem;
  }
`;

const WrapLargeWidth = css`
  max-width: calc(66.125rem + 14.444444444vw);

  @media screen and (min-width: 1440px) {
    max-width: 79.125rem;
  }
`;

const WrapMaxWidth = css`
  max-width: calc(83.570rem + 14.444444444vw);

  @media screen and (min-width: 1440px) {
    max-width: 105rem;
  }
`

const Shared = css`
  margin: 0 auto;
  padding-right: 7.222222222vw;
  padding-left: 7.222222222vw;

  @media screen and (min-width: 1440px) {
    padding-right: 6.5rem;
    padding-left: 6.5rem;
  }
`;

const Wrap = styled.div`
  ${WrapWidth};
  ${Shared};
`;

const WrapXs = styled.div`
  ${WrapXsWidth};
  ${Shared};
`;

const WrapSmall = styled.div`
  ${WrapSmallWidth};
  ${Shared};
`;

const WrapMedium = styled.div`
  ${WrapMediumWidth};
  ${Shared};
`;

const WrapLarge = styled.div`
  ${WrapLargeWidth};
  ${Shared};
`;

const WrapMax = styled.div`
  ${WrapMaxWidth};
  margin: 0 auto;
  padding-right: 7.222222222vw;
  padding-left: 7.222222222vw;

  @media screen and (min-width: 1440px) {
    padding-right: 4.875rem;
    padding-left: 4.875rem;
  }
`;


export {
  Wrap,
  WrapWidth,
  WrapSmall,
  WrapSmallWidth,
  WrapXs,
  WrapXsWidth,
  WrapMedium,
  WrapMediumWidth,
  WrapLarge,
  WrapLargeWidth,
  WrapMax,
  WrapMaxWidth
}