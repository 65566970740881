import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import { Wrap } from './wraps';
import { Color, FontOsynlig } from '../../config/variables';

import * as Icons from './Icon';

interface Props {
  placeholder?: string;
  icon?: string;
  type?: string;
  required?: Boolean;
  autocomplete?: string;
  className?: string;
  name?: string;
  onChange?: Function;
  value: string;
}

export default class TextInput extends Component<Props> {
  static defaultProps: Props = {
    type: 'text',
    value: '',
  };

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { type, placeholder, autocomplete, required, icon, name, className, onChange, value } = this.props;

    const Icon = icon && Icons[icon];

    return (
      <Outer className={className}>
        <Input
          type={type}
          name={name}
          placeholder={placeholder}
          aria-label={placeholder}
          icon={Boolean(icon)}
          required={required}
          autocomplete={autocomplete}
          onChange={onChange}
          value={value}
        />
        {Icon && <Icon className="icon" viewBox="0 0 24 24" />}
      </Outer>
    );
  }
}

const Shared = css`
  grid-row-start: 1;
  grid-column-start: 1;
`;

const Outer = styled.div`
  display: grid;
  position: relative;
  stroke: ${Color.slate};
  grid-template-columns: 1fr;

  svg {
    ${Shared};
    margin-left: 1rem;
    align-self: center;
  }
`;

const Input = styled.input`
  appearance: none;
  border: 0;
  border-radius: 0;
  line-height: 1.75;
  padding: 0.625em 1.125em;
  color: ${Color.slate};
  outline: none;
  box-shadow: inset 0 0 0 2px transparent;
  ${Shared};
  ${props =>
    props.icon &&
    `
    padding-left: 3.25rem;
  `};
  width: 100%;

  &:before {
    width: 1em;
    height: 1em;
    background-color: red;
    content: '';
  }

  &:hover {
    box-shadow: inset 0 0 0 2px ${Color.citrus};
  }

  &:focus {
    box-shadow: inset 0 0 0 2px ${Color.slate};
  }

  &.error {
    box-shadow: inset 0 0 0 2px ${Color.begonia};
  }

  &::placeholder {
    color: ${Color.slate3};
  }
`;
