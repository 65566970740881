import React, { Component, createRef } from 'react';
import styled from 'styled-components';

import TextInput from './shared/TextInput';
import { WrapSmall, WrapSmallWidth } from './shared/wraps';
import { Button, Checkbox } from './shared';
import { Color, ScalingSize, DynamicPadding2x } from '../config/variables';

interface IState {
  email: string;
  termsAccepted: boolean;
}

export default class Footer extends Component<{}, IState> {
  private formRef = createRef<HTMLFormElement>();

  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      termsAccepted: false,
    };
  }

  render() {
    const { email, termsAccepted } = this.state;

    return (
      <Outer>
        <form
          ref={this.formRef}
          action="https://osynlig.us2.list-manage.com/subscribe/post?u=51f766054e084b015dd7f0252&amp;id=aa00536768"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          rel="noopener"
          onSubmit={this.onSubmit.bind(this)}
        >
          <Inner>
            <Heading>Få våra bästa tips och spaningar direkt till mailen</Heading>
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input type="text" name="b_51f766054e084b015dd7f0252_aa00536768" tabIndex={-1} defaultValue="" />
            </div>
            <StyledTextInput
              onChange={this.onEmailChange.bind(this)}
              as={TextInput}
              icon="Mail"
              type="email"
              name="EMAIL"
              placeholder="Skriv din epost"
              required
              autocomplete="email"
              value={email}
            />
            <StyledCheckbox
              onChange={this.onTermsChange.bind(this)}
              checked={termsAccepted}
              as={Checkbox}
              id="terms"
              text={'Jag godkänner att ta emot utskick i enighet med <a href="/integritetspolicy">integritetspolicyn</a>'}
              required
            />
            <StyledButton as={Button} text="Prenumerera" />
          </Inner>
        </form>
      </Outer>
    );
  }

  onEmailChange(ev) {
    this.setState({
      email: ev.target.value,
    });
  }

  onTermsChange(ev) {
    this.setState({
      termsAccepted: ev.target.checked,
    });
  }

  onSubmit(ev) {
    this.formRef.current.submit();

    setTimeout(() => {
      this.setState({
        email: '',
        termsAccepted: false,
      });
    }, 500);
  }
}

const Outer = styled.section`
  background-color: ${Color.champ};
  ${DynamicPadding2x}
  position: relative;
`;

const Inner = styled(WrapSmall)`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 100%;
  grid-gap: 2em 1em;

  @media screen and (min-width: 480px) {
    grid-template-columns: 1fr 10.75em;
  }

  @media screen and (min-width: 960px) {
    grid-gap: 3.333333333vw 1.666666667vw;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 10.75em;
  }

  @media screen and (min-width: 884px) {
    grid-template-columns: 20em 1fr 10.75em;
  }

  @media screen and (min-width: 1440px) {
    grid-gap: 3em 1.5em;
  }
`;

const Heading = styled.h2`
  ${WrapSmallWidth};
  color: ${Color.slate};
  text-align: center;
  justify-self: center;
  margin: 0;
  width: 15em;
  ${ScalingSize('font-size', 24, 48)};
  line-height: 1.25;
  letter-spacing: -0.02em;

  @media screen and (min-width: 480px) {
    grid-column: 1 / 3;
  }

  @media screen and (min-width: 768px) {
    grid-column: 1 / 4;
  }
`;

const StyledTextInput = styled.div`
  width: 100%;
  max-width: 20em;
  margin: 0 auto;

  @media screen and (min-width: 480px) {
    grid-column: 1 / 3;
    max-width: none;
  }

  @media screen and (min-width: 768px) {
    grid-column: 1;
  }
`;

const StyledCheckbox = styled.div`
  max-width: 14.5em;
  margin: 0 auto;

  @media screen and (min-width: 480px) and (max-width: 767px) {
    margin-right: 0;
  }
`;

const StyledButton = styled.div`
  max-width: 10.75em;
  margin: 0 auto;
`;
