import React from 'react';
import { Color } from '../../config/variables';
import ScrollingColorBackground from 'react-scrolling-color-background';

const OsynligScrollingColorBackground = () => (
  <ScrollingColorBackground style={StyledScrollingColorBackground} selector="[data-color]" colorDataAttribute="data-color" initialRgb={Color.white} />
);

const StyledScrollingColorBackground = {
  position: 'fixed',
  zIndex: -1,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  pointerEvents: 'none',
};

export default OsynligScrollingColorBackground;
