import React from 'react';
import styled, { css } from 'styled-components';

import { Color } from '../../config/variables';
interface ParagraphProps {
  children?: any;
  list?: boolean;
  markdown?: boolean;
  cta?: boolean;
  cookie?: boolean;
  article?: boolean;
}

const Paragraph = ({ children, list, markdown, cta, cookie, article }: ParagraphProps) => (
  <StyledParagraph list={list} markdown={markdown} cta={cta} cookie={cookie} article={article}>
    {children}
  </StyledParagraph>
);

const StyledParagraph = styled.p`
  font-size: 0.875em;
  line-height: 1.5rem;
  color: ${Color.slate3};
  margin: 0 0 1.125rem;

  ${props =>
    props.cta || props.article
      ? css`
          margin: 0 0 1.5rem;
        `
      : null};

  ${props =>
    props.markdown
      ? css`
          font-size: 1rem;
          line-height: 1.75;
          margin: 2rem 0 1rem;
        `
      : null};

  ${props =>
    props.cookie
      ? css`
          font-size: unset;
          margin: 0 1.5rem 0 0;
        `
      : null};
`;

export default Paragraph;
