/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

import Header from './header';
import Cta from './Cta';
import CookieWarning from './CookieWarning';
import Footer from './footer';
import Newsletter from './Newsletter';
import './layout.scss';
import { Color } from '../config/variables';
import {
  OsynligScrollingColorBackground,
  // OsynligScrollingCircle
} from '../components/shared';

const query = graphql`
  query {
    cms {
      defaultCta {
        Teasers {
          Heading
          Image {
            url
            alternativeText
            imageFile {
              relativePath
            }
          }
          Link {
            Title
            URL
          }
          Text
        }
      }
      footer(publicationState: LIVE) {
        id
        Column1
        Column2
        Column3
        Column4
      }
    }
  }
`;

interface Props {
  children: Node;
}

interface State {
  mobileMenuActive: boolean;
}

class Layout extends Component<Props & any, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mobileMenuActive: false,
    };
  }

  render() {
    const { children, location, pageContext } = this.props;
    const { mobileMenuActive } = this.state;

    const page = pageContext.page;

    return (
      <StaticQuery
        query={query}
        render={data => {
          const defaultTeasers = data.cms.defaultCta.Teasers;
          const teasers = page && page.Teaser && page.Teaser.length > 0 ? page.Teaser : defaultTeasers; // Page will be undefined for static pages, e.g. 404

          // Will default to true on static pages (e.g. 404)
          const showTeasers = !page || page.Teasers === true;
          const showNewsletter = !page || page.Newsletter === true;

          return (
            <Fragment>
              <Wrap data-color={Color.white}>
                <CookieWarning />

                <Header
                  location={location}
                  mobileMenuActive={mobileMenuActive}
                  onCloseMobileMenu={this.onCloseMobileMenu.bind(this)}
                  onToggleMobileMenu={this.onToggleMobileMenu.bind(this)}
                />

                {showTeasers && (
                  <Fade bottom distance={'10%'} fraction={0.2}>
                    <Cta items={teasers} />
                  </Fade>
                )}

                <OsynligScrollingColorBackground />
                <Main id="main">{children}</Main>

                {showNewsletter && <Newsletter />}

                <Footer data-color={Color.white} columns={data.cms.footer}/>

                <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0">
                  <defs>
                    <clipPath clipPathUnits="objectBoundingBox" id="contact">
                      <rect x={0} y={0} width={1} height={0.4} />
                      <ellipse cx={0.5} cy={0.55234375} rx={0.5} ry={0.440625} />
                    </clipPath>
                  </defs>
                </svg>
              </Wrap>
            </Fragment>
          );
        }}
      />
    );
  }

  onToggleMobileMenu(forceSate: boolean = undefined) {
    const { mobileMenuActive } = this.state;

    if (typeof forceSate === 'object') {
      forceSate = undefined;
    }

    this.setState({
      mobileMenuActive: forceSate !== undefined ? forceSate : !mobileMenuActive,
    });

    document.documentElement.classList[mobileMenuActive ? 'remove' : 'add']('mobile-menu-active');
  }

  onCloseMobileMenu() {
    this.onToggleMobileMenu(false);
  }
}

const Wrap = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  order: -20;
  flex: 1 1 auto;
`;

export default Layout;
