import React from 'react';
import styled, { keyframes } from 'styled-components';

import {
  OsynligScrollingColorBackground,
  // OsynligScrollingCircle
} from './';

const Page = ({ children }) => (
  <>
    <Inner>{children}</Inner>
    <OsynligScrollingColorBackground />
    {/* <OsynligScrollingCircle /> */}
    {/* <OsynligScrollingDoughnut /> */}
  </>
);
const PageAnimationIn = keyframes`
  from {
    transform: translateX(5.5555556vw);
  }

  to {
    transform: none;
  }
`;

const PageAnimationOut = keyframes`
  from {
    transform: none;
  }

  to {
    transform: translateX(-5.5555556vw);
  }
`;

const Inner = styled.div`
  transition: all 450ms ease;
  .mobile-menu-active & {
    transform: translateX(-2vw);
    transition: all 450ms ease;
  }

  .tl-wrapper-status--exiting & {
    animation: ${PageAnimationOut} 0.325s ease;
  }

  .tl-wrapper-status--entering & {
    animation: ${PageAnimationIn} 0.325s ease;
  }
`;

export default Page;
