import React from 'react';
import styled, { css } from 'styled-components';

import { Color, ScalingSize } from '../../config/variables';
interface Heading3Props {
  children?: any;
  list?: boolean;
  markdown?: boolean;
}

const Heading3 = ({ children, list, markdown }: Heading3Props) => (
  <StyledHeading3 list={list} markdown={markdown}>
    {children}
  </StyledHeading3>
);

const StyledHeading3 = styled.h3`
  color: ${Color.slate};
  line-height: 1.333333333;
  letter-spacing: -0.02em;
  margin: 0 0 1rem;

  ${props =>
    props.list
      ? css`
          color: ${Color.slate2};
          ${ScalingSize('font-size', 16, 32)};
        `
      : null};

  ${props =>
    props.markdown
      ? css`
          ${ScalingSize('font-size', 16, 24)};
          margin: 2rem 0 1rem;
        `
      : null}
`;

export default Heading3;
